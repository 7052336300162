import React from "react";
import RegularFooter from "./RegularFooter";
import styles from "./footer.module.css";
import { useStoreContext } from "../../contexts/StoreDataContext";
import Link from "next/link";
import Image from "next/image";

export default function FeaturedFooter() {
  const storeData = useStoreContext();
  const navigateToHome = () => {
    const url = `https://dm2buy.com?utm_source=footer&utm_campaign=${storeData.subdomain}`;
    mixpanel.track("footer_cta", {
      referrer: storeData.subdomain,
    });
    window.open(url, "_blank").focus();
  };

  const navigateToInstagram = () => {
    const url = `https://www.instagram.com/${storeData.instagramHandle}`;
    window.open(url, "_blank").focus();
  };

  const isNoLegaInfoAvailable = !storeData?.legalInfo;

  //Legal info available
  if (!isNoLegaInfoAvailable) {
    const legalInfo = storeData?.legalInfo;
    const terms = legalInfo?.terms;
    const contacts = legalInfo?.contacts;
    const returns = legalInfo?.returns;
    const privacy = legalInfo?.privacy;
    const about = legalInfo?.about;
    const policy = legalInfo?.policy;
    const care = legalInfo?.care;
    const size = legalInfo?.size;
    const isNoLegalDataAvailable =
      !terms && !contacts && !returns && !privacy && !about && !policy && !care && !size;

    return (
      <footer className={styles.footer}>
        {/* <Image src="/instagram-4.png" width="24" height="24" /> */}
        {/* Quick Links  */}
        <div className={styles.quickLinks}>
          <div className={styles.heading}> Quick Links</div>
          {policy && <Link href="/policy">Privacy Policy</Link>}
          {care && <Link href="/care">Product Care</Link>}
          {size && <Link href="/size">Size</Link>}
          {terms && <Link href="/terms">Terms</Link>}
          {returns && <Link href="/returns">Shipping & Return</Link>}
          {contacts && <Link href="/contact">Contact Us</Link>}
          {about && <Link href="/about">About</Link>}
        </div>
        {/* About Us  */}
        <div>
          <div className={styles.heading}>About Us</div>
          <div className={styles.aboutDetails}>{storeData?.storeBio}</div>
        </div>
        {/* Social */}
        <div>
          <div className={styles.heading}> Social </div>
          <div>
            <Image
              onClick={navigateToInstagram}
              src="https://dm2buy-aqbqh9cwb5cwb9he.z02.azurefd.net/dm2buy/dm2buy_instagram.png"
              alt={""}
              width={20}
              height={20}
              layout="fixed"
            />
          </div>
        </div>
        {/* Cashfree */}
        <div>
          <div className={styles.heading}>Secure Payments via Cashfree</div>
          <div className={styles.images}>
            <Image
              src="https://dm2buy-aqbqh9cwb5cwb9he.z02.azurefd.net/dm2buy/dm2buy_asset_allpg.svg"
              alt={""}
              layout="fixed"
              width={500} // Original image width
              height={80} // Original image height
            />
          </div>
        </div>
        <div className={styles.poweredBy}>
          <div onClick={navigateToHome}>
            <p>
              ©2024 by {storeData.storeName}. Made with love on
              <a style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}>
                &nbsp;dm2buy
              </a>
              ✌🏼
            </p>
          </div>
        </div>
      </footer>
    );
  }

  return <RegularFooter />;
}
