//import * as Sentry from "@sentry/nextjs";
import mixpanel from "mixpanel-browser";
import domSerializer from "dom-serializer";
import { parseDocument } from "htmlparser2";
import { blobServiceClient } from "./azureBlobClient";
mixpanel.init("88d3dac90690db0293a6e1acec5ca152", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});
//console.log("mixpanel init-> " + mixpanel)

const getSubDomainOfPage = () => {
  const { host } = window.location;

  let splitHost = host.split(".");
  console.log("===========  TRIGGERED ===========");
  if (!splitHost.includes("dm2buy") && !splitHost.includes("localhost:3000")) {
    return new Promise((resolve, reject) => {
      fetch(`/api/store/getSubdomain/${encodeURI(window.location.host)}`)
        .then((response) => response.json())
        .then((res) => {
          splitHost[0] = res.subdomain;
          console.log("splitHost[0] ==========: ", splitHost[0]);
          resolve(
            splitHost[0] == "localhost:3000" || splitHost[0] == "192" ? "testing141" : splitHost[0],
          );
        })
        .catch((err) => reject());
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve(
        splitHost[0] == "localhost:3000" || splitHost[0] == "192" ? "testing141" : splitHost[0],
      );
    });
  }
};

const sanitizeHtml = (htmlContent) => {
  try {
    // Parse the HTML content into a DOM-like structure
    const document = parseDocument(htmlContent);

    // Serialize the fixed DOM back to a string
    const fixedHTML = domSerializer(document);

    return fixedHTML;
  } catch (error) {
    console.error("Error fixing HTML:", error);
    return Text; // Return an empty string or handle the error accordingly
  }
};

const airtableBaseId = process.env.AIRTABLE_BASE_ID;
const serverEndpoint = process.env.SERVER_ENDPOINT;

const cashfreeEnv = process.env.CASHFREE_ENV;

const environment = process.env.ENV;
const publicServerEndpoint = process.env.NEXT_PUBLIC_SERVER_ENDPOINT;
const guid = () => {
  var result, i, j;
  result = "";
  for (j = 0; j < 32; j++) {
    if (j == 8 || j == 12 || j == 16 || j == 20) result = result + "-";
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result = result + i;
  }
  return result;
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

//Stock check is not actually done through this method now
async function getStockAvailability(cartProducts) {
  if (!cartProducts || !cartProducts.length) return false;
  let isStockAvailable = true;
  return isStockAvailable;
}

const uploadFileToAzure = async (file, containerName) => {
  if (!file || !containerName) {
    throw new Error("File and containerName are required.");
  }

  try {
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = `${Date.now()}-${file.name}`;

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
    });

    return blockBlobClient.url;
  } catch (error) {
    console.error("Error uploading file to Azure Blob Storage:", error);
    throw error;
  }
};

export {
  getSubDomainOfPage,
  airtableBaseId,
  serverEndpoint,
  guid,
  isNumeric,
  environment,
  cashfreeEnv,
  getStockAvailability,
  mixpanel,
  publicServerEndpoint,
  sanitizeHtml,
  uploadFileToAzure,
};
